import { store } from '../../storeConfig/store';
import rest from '../../../utility/rest/rest';

export const getNotifications = lang => {
  store.dispatch(loadingNotifications());
  return async (dispatch) => {
    let unReadCount = 0;
    const unReadCountRest = rest.get(process.env.REACT_APP_API_SERVICE + '/notifications/unread-count');

    let notifications = [];
    let notificationsError;
    if (lang === 'us') lang = 'en';
    const notificationsRest = rest.get(process.env.REACT_APP_API_SERVICE + `/notifications?lang=${lang}`);

    await unReadCountRest.then(resp => unReadCount = resp?.data?.unreadCount || 0);

    await notificationsRest.then(resp => notifications = resp?.data || []).catch(err => notificationsError = err);

    dispatch(successNotifications(unReadCount, notifications));
    if (notificationsError) {
      throw notificationsError
    }
  }
};

const loadingNotifications = () => ({
  type: 'LOADING_NOTIFICATIONS'
});

const successNotifications = (unReadCount, notifications) => ({
  type: 'SUCCESS_NOTIFICATION',
  payload: {unReadCount, notifications}
});

export const readNotification = id => {
  store.dispatch(readNotificationItem(id));
  return (dispatch) => {
    rest.patch(process.env.REACT_APP_API_SERVICE + `/notifications/${id}`).then(() => {
      rest.get(process.env.REACT_APP_API_SERVICE + '/notifications/unread-count').then(resp => {
        dispatch(getUnReadCountNotification(resp?.data?.unreadCount || 0))
      })
    }).catch(() => {
      dispatch(unReadNotificationItem(id));
    });
  }
};

const readNotificationItem = id => ({
  type: 'READ_NOTIFICATION',
  payload: id
});

const unReadNotificationItem = id => ({
  type: 'UN_READ_NOTIFICATION',
  payload: id
});

const getUnReadCountNotification = count => ({
  type: 'UN_READ_COUNT_NOTIFICATION',
  payload: count
});
