import React, { useState } from 'react';
import { Media } from 'reactstrap';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from "../common/Modal/Modal";

const NotificationItem = props => {

  const { notification, onClick, viewInList = false, maxChars = 0 } = props;

  const[state, setState] = useState({
    open: false
  });

  const handleClickItem = () => {
    if (maxChars > 0 && notification.message.length > maxChars) {
      setState(prevState => ({
        ...prevState,
        open: true
      }));
    }
    if (onClick) {
      onClick(notification)
    }
  };

  const handleCloseModal = () => {
    setState(prevState => ({
      ...prevState,
      open: false
    }));
  };

  const renderMessage = () => {
    if (maxChars > 0) {
      if (notification.message.length > maxChars) {
        return `${notification.message.substr(0, maxChars)} ...`
      } else {
        return notification.message
      }
    } else {
      return notification.message
    }
  };

  const renderBody = () => {
    if (viewInList) {
      return (
          <Media
              tag="li"
              className={notification.read ? "mail-read" : "mail-unread"}
              onClick={handleClickItem}
          >
            <Media className="pr-50" tag="div" left>
              {
                notification.read ?
                    <Icon.Folder
                        className="font-medium-5 success"
                        size={35}
                    /> :
                    <Icon.Mail
                        className="font-medium-5 primary"
                        size={35}
                    />
              }
            </Media>
            <Media body>
              <div className="user-details flex-wrap">
                <div className="mail-items">
                  <h5 className="text-bold-600 mb-25 ">{notification.title}</h5>
                </div>
                <div className="mail-meta">
              <span className="float-right">
                <span
                    className={`bullet bullet-${notification.read ? 'success' : 'primary'} bullet-sm d-none d-md-inline-block mr-1`}
                />
                <span className="mail-date">{moment(notification.createdAt).format('YYYY/MM/DD')}</span>
              </span>
                </div>
              </div>
              <div className="mail-message">
                <p className="notification-text">
                  {renderMessage()}
                </p>
              </div>
            </Media>
          </Media>
      )
    } else {
      return (
          <div className="d-flex justify-content-between" onClick={handleClickItem}>
            <Media className="d-flex align-items-start">
              <Media left href="#">
                {
                  notification.read ?
                      <Icon.Folder
                          className="font-medium-5 success"
                          size={21}
                      /> :
                      <Icon.Mail
                          className="font-medium-5 primary"
                          size={21}
                      />
                }
              </Media>
              <Media body>
                <Media heading className={`${notification.read ? 'success' : 'primary'} media-heading`} tag="h6">
                  {notification.title}
                </Media>
                <p className="notification-text">
                  {renderMessage()}
                </p>
              </Media>
              <small>
                <time
                    className="media-meta"
                    dateTime="2015-06-11T18:29:20+08:00"
                >
                  {moment(notification.createdAt).format('YYYY/MM/DD')}
                </time>
              </small>
            </Media>
          </div>
      )
    }
  };

  return (
      <>
        <Modal
            body={
              <p className="notification-text">
                {notification.message}
              </p>
            }
            open={state.open}
            headerText={notification.title}
            hideSubmit
            onClose={handleCloseModal}
        />
        {renderBody()}
      </>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object,
  onClick: PropTypes.func,
  viewInList: PropTypes.bool,
  maxChars: PropTypes.number
};

export default NotificationItem;
