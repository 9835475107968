import React from 'react';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import * as Icon from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationItem from './NotificationItem';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { readNotification } from '../../redux/actions/notifications';

const Notifications = () => {

  const history = useHistory();

  const dispatch = useDispatch();

  const {unReadCount, notifications, loadingNotification} = useSelector(state => state?.notifications?.notifications);

  const handleClickNotification = data => {
    if (!data.read) {
      dispatch(readNotification(data.id));
    }
  };

  const renderNotification = () => {
    if (loadingNotification) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 30,
          paddingBottom: 30
        }}>
          <Spinner color="primary" size="lg"/>
        </div>
      )
    } else {
      return notifications.map(item =>
        <NotificationItem
          onClick={handleClickNotification}
          key={item.id}
          maxChars={25}
          notification={item}/>
      )
    }
  };

  const handleReadAll = () => history.push('/notifications');

  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification nav-item"
    >
      <DropdownToggle tag="a" className="nav-link nav-link-label">
        <Icon.Bell size={21} />
        {
          unReadCount > 0 && (
            <Badge pill color="primary" className="badge-up">
              {" "}
              {unReadCount}{" "}
            </Badge>
          )
        }
      </DropdownToggle>
      <DropdownMenu tag="ul" right className="dropdown-menu-media">
        <li className="dropdown-menu-header">
          <div className="dropdown-header mt-0">
            {
              unReadCount > 0 && (
                <h3 className="text-white">{unReadCount} New</h3>
              )
            }
            <span className="notification-title">App Notifications</span>
          </div>
        </li>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false
          }}
        >
          {renderNotification()}
        </PerfectScrollbar>
        <li className="dropdown-menu-footer" onClick={handleReadAll}>
          <DropdownItem tag="a" className="p-1 text-center">
            <span className="align-middle">Read all notifications</span>
          </DropdownItem>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
};

export default Notifications;
