import {OPEN_SNACK, CLOSE_SNACK} from "../../actions/snackbar/Index";

const initialState = {
    open: false,
    type:"",
    message: ""
};

const snackbar = (state = initialState, action) => {
   switch (action.type) {
       case OPEN_SNACK:
           return {open: true, message: action.payload.message, type: action.payload.type}

       case CLOSE_SNACK:
           return {...initialState,open: false}

       default:
           return state
   }
};

export  default snackbar