
const initialData = {
  usersMe: {},
  company: {}
};

const general = (state = initialData, action) => {
  switch (action.type) {
    case "USERS_ME": {
      return {...state, usersMe: action.payload}
    }
    case 'SET_COMPANY': {
      return {
        ...state,
        company: action.payload
      }
    }
    default: {
      return {...state}
    }
  }
};

export default general;
