import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/index";
import snackbar from './snackbar/Index';
import general from './general';
import notifications from './notifications';
import language from './language';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  snackbar,
  general,
  notifications,
  language
});

export default rootReducer
