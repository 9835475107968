import AuthStorage from "./AuthSorage";
import axios from "axios";
import {history} from "../../history";

const Logout = (token) => {
    axios({
        method: "POST",
        headers: {
            Authorization: `${token}`
        },
        url: `/auth/logout`
    })
        .then(() => {
            AuthStorage.removeLocalAuthToken();
            AuthStorage.removeSessionAuthToken();
            history.replace('/auth/login');
        })
        .catch(() => {
            AuthStorage.removeLocalAuthToken();
            AuthStorage.removeSessionAuthToken();
            history.replace('/auth/login')
        });
};

export default Logout;

