import React from "react";
import propTypes from 'prop-types';
import {
    Button,
    Modal as ReactModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"

class Modal extends React.Component {

    static propTypes = {
        body: propTypes.node,
        confirmText: propTypes.string,
        cancelText: propTypes.string,
        headerText: propTypes.string,
        open: propTypes.bool,
        onAccept: propTypes.func,
        hideSubmit: propTypes.bool,
        confirmDisabled: propTypes.bool
    };

    static defaultProps = {
        confirmText: 'Accept',
        open: false,
        cancelText: 'Cancel',
        hideSubmit: false,
        confirmDisabled: false
    };
    state = {
        open: this.props.open,
        disabled: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.open !== this.props.open) {
            this.setState({open: this.props.open})
        }

        if (prevState.confirmDisabled !== this.props.confirmDisabled) {
            this.setState({confirmDisabled: this.props.confirmDisabled})
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }))
    };

    handleAccept = () => {
       if(this.props.onAccept)  this.props.onAccept()
    };

    handleClose = () => {
        if(this.props.onClose) this.props.onClose()
    }

    render() {
        return (
            <React.Fragment>
                <ReactModal
                    isOpen={this.state.open}
                    toggle={this.toggleModal}
                    className={this.props.className}
                    onClosed={this.handleClose}
                >
                    <ModalHeader toggle={this.toggleModal}>
                        {this.props.headerText}
                    </ModalHeader>
                    <ModalBody className={'py-2'}>
                        {this.props.body}
                    </ModalBody>
                    {!this.props.hideSubmit &&
                        <ModalFooter>
                            <Button color="primary"  disabled={this.state.confirmDisabled} onClick={this.handleAccept}>
                                {this.props.confirmText}
                            </Button>
                        </ModalFooter>
                    }
                </ReactModal>
            </React.Fragment>
        )
    }
}

export default Modal
