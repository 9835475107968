
const initialData = {
  loadingNotification: false,
  notifications: [],
  unReadCount: 0
};

const notifications = (state = initialData, action) => {
  switch (action.type) {
    case 'LOADING_NOTIFICATIONS': {
      return {
        ...state,
        loadingNotification: true
      }
    }
    case 'SUCCESS_NOTIFICATION': {
      return {
        ...state,
        notifications: action.payload.notifications,
        unReadCount: action.payload.unReadCount,
        loadingNotification: false
      }
    }
    case 'READ_NOTIFICATION': {
      let notifications = [...state.notifications];
      const index = notifications.findIndex(item => item.id === action.payload);
      if (index > -1) {
        notifications[index].read = true;
      }
      return {
        ...state,
        notifications
      }
    }
    case 'UN_READ_NOTIFICATION': {
      let notifications = [...state.notifications];
      const index = notifications.findIndex(item => item.id === action.payload);
      if (index > -1) {
        notifications[index].read = false;
      }
      return {
        ...state,
        notifications
      }
    }
    case 'UN_READ_COUNT_NOTIFICATION': {
      return {
        ...state,
        unReadCount: action.payload
      }
    }
    default: {
      return {...state}
    }
  }
};

export default notifications;
