import React, {useState} from 'react';
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import {useSelector, useDispatch} from "react-redux";
import {switchLanguage} from '../../redux/actions/language';

const Language = () => {

    const dispatch = useDispatch();

    const langStore = useSelector(state => state?.language?.language || {});

    const[state, setState] = useState({langDropdown: false});

    const handleLangDropdown = () => {
        setState(prevState => ({
            ...prevState,
            langDropdown: !prevState.langDropdown
        }));
    };

    return (
        <>
            <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={state.langDropdown}
                toggle={handleLangDropdown}
                data-tour="language">

                <DropdownToggle tag="a" className="nav-link">
                    <ReactCountryFlag
                        className="country-flag"
                        countryCode={langStore.countryCode}
                        svg
                    />
                    <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langStore.langArr[langStore.countryCode]}
                  </span>
                </DropdownToggle>

                <DropdownMenu right>

                    <DropdownItem tag="a" onClick={() => dispatch(switchLanguage('tr'))}>
                        <ReactCountryFlag className="country-flag" countryCode="tr" svg />
                        <span className="ml-1">Turkey</span>
                    </DropdownItem>

                    <DropdownItem tag="a" onClick={() => dispatch(switchLanguage('us'))}>
                        <ReactCountryFlag className="country-flag" countryCode="us" svg />
                        <span className="ml-1">English</span>
                    </DropdownItem>

                </DropdownMenu>
            </Dropdown>
        </>
    )
};

export default Language
