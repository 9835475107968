import rest from "../../../utility/rest/rest";
import {store} from "../../storeConfig/store";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const FETCH_LOGIN = "FETCH_LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SEND_CODE = "SEND_CODE";

export const login_pending = () => {
    return {
        type: "LOGIN_PENDING"
    };
};

export const fetch_Login = data => {
    return {
        type: "FETCH_LOGIN",
        payload: data
    };
};

export const sendCode = data => {
    return {
        type: "SEND_CODE",
        payload: data
    };
};

export const login_error = () => {
    return {
        type: "LOGIN_ERROR"
    };
};

export const loginAction = (code, number) => {
    store.dispatch(login_pending());
    return function (dispatch, getState) {
        let body = {
            "countryCode": code,
            "mobileNumber": number,
        };
        return rest.post(process.env.REACT_APP_API_SERVICE + `/auth/send-code`, body)
            .then(data => {
                dispatch(fetch_Login(data))
            })
            .catch(e => dispatch(login_error(e)));
    };
};

export const sendVerificationCode = (body) => {
    return async function (dispatch) {
        return  rest.post(process.env.REACT_APP_API_SERVICE + `/auth/verify`,body)
            .then(data => {
                dispatch(sendCode(data))
            })
            .catch(e =>dispatch(login_error(e)))
    }
};
