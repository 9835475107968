import axios from 'axios';
import AuthStorage from "../Auth/AuthSorage";

const CancelToken = axios.CancelToken;
let cancelRequest;
const rest = axios.create({
    responseType: "json",
    headers: {
        "Accept": "application/json",
        "Content-type": "application/json"
    }
});

rest.interceptors.request.use(
    function (config) {
        const token = AuthStorage.getToken();
        if (!config.headers.Authorization && token.length) config.headers.Authorization = `Bearer ${token}`;
        config.cancelToken = new CancelToken(function executor(c) {
            cancelRequest = c;
        });

        return config

    }, function (error) {
        return Promise.reject(error);
    }
);


export {cancelRequest}
export default rest
