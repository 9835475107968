import React, {useState} from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import * as Icon from "react-feather";
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ImageProfile from '../../../components/ImageProfile';
import Notifications from '../../../components/Notifications';
import Modal from "../../../components/common/Modal/Modal";
import Language from '../../../components/Language';

const UserDropdown = props => {
  const [state, setState] = useState({logoutModal: false})
  const history = useHistory();

  const handleEditProfile = () => history.push('/profile/edit');

    const handleCloseModal = () => {
        setState({logoutModal: false})
    };

    const openLogoutModal = () => {
        setState({logoutModal: true})
    };

    const onConfirm = () => {
        handleLogout()
    };

  const handleLogout = () => props.handleLogOut && props.handleLogOut();

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={handleEditProfile}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={openLogoutModal}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
        <Modal
            body={<div>Are you sure you want to quit?</div>}
            open={state.logoutModal}
            confirmText={'Accept'}
            headerText={'Logout'}
            onAccept={onConfirm}
            onClose={handleCloseModal}
        />
    </DropdownMenu>
  )
};

const NavbarUser = props => {

  const usersMe = useSelector(state => state?.general?.general?.usersMe || {});

    return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <Language />
      <Notifications/>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {usersMe.username || '-'}
              </span>
          </div>
          <span data-tour="user">
            <ImageProfile img={usersMe.image} width='40' height='40' radius={'max'}/>
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )
};

export default NavbarUser
