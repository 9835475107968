import AuthStorage from "../../../utility/Auth/AuthSorage";
import defaultConfig from '../../../configs/defaultConfig';

const initialState = {
    countryCode: AuthStorage.getCountryCode() || defaultConfig.countryCode,
    langArr: {
        "us" : "English",
        "tr" : "Turkey"
    }
};

const language = (state = initialState, action) => {
    switch (action.type) {
        case 'SWITCH_LANGUAGE': {
            AuthStorage.setCountryCode(action.payload);
            return {
                ...state,
                countryCode: action.payload
            }
        }
        default: {
            return {...state}
        }
    }
};

export default language;
