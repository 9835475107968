import React from 'react';
import cloudinary from '../../configs/cloudinaryConfig';
import { Image, Transformation } from 'cloudinary-react';
import PropTypes from 'prop-types';
import { User } from 'react-feather'

const ImageProfile = props => {

  const {
    img,
    radius,
    width,
    height,
    url,
    alt = ''
  } = props;

  if (img) {
    return (
      <Image
        cloudName={cloudinary.cloudName}
        publicId={img}
        width={width}
        secure="true">
        <Transformation width={width} height={height} gravity="face" crop="fill" />
        {radius && (<Transformation radius={radius} />)}
      </Image>
    )
  } else if (url) {
    return <img src={url} style={{width, height, radius}} alt={alt}/>
  } else {
    return <User size={width}/>
  }
};

ImageProfile.propTypes = {
  img: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // max or number of string: 20 or 30...
};

export default ImageProfile;
