import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "profile",
    title: "Profile",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin"],
    navLink: "/profile",
  },
  {
    id: "employee",
    title: "Employee",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: "employeeList",
        title: "Employee List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/employee/list",

      },
      {
        id: "employeeAdd",
        title: "Employee Add",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/employee/add",

      }
    ]
  },
  {
    id: "transactions",
    title: "Transactions",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/transactions"
  },
  {
    id: "invoice",
    title: "Invoice",
    type: "collapse",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    children: [
      {
        id: "invoiceList",
        title: "Invoice List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/invoice",

      },
    ]
  },
  {
    id: "wallet",
    title: "Company Wallet",
    type: "item",
    icon: <Icon.DollarSign size={20}/>,
    navLink: "/wallet"
  },
  {
    id: "notificationList",
    title: "Notification List",
    type: "item",
    icon: <Icon.Bell size={15} />,
    navLink: "/notifications",

  }
]

export default navigationConfig
