import get from "lodash/get";
import defaultConfig from "../../configs/defaultConfig";

const AuthStorage = {
    setLocalAuthToken: function(value) {
        localStorage.setItem("TOKEN", JSON.stringify(value));
    },

    setSessionAuthToken: function(value) {
        sessionStorage.setItem("TOKEN", JSON.stringify(value));
    },

    getLocalAuthToken: function() {
        let storageData = localStorage.getItem("TOKEN");
        let result = {};
        if (storageData !== null && storageData !== undefined) {
            result = JSON.parse(storageData);
        }
        return result;
    },

    getSessionAuthToken: function() {
        let sessionData = sessionStorage.getItem("TOKEN");
        let result = {};
        if (sessionData !== null && sessionData !== undefined) {
            result = JSON.parse(sessionData);
        }
        return result;
    },

    removeLocalAuthToken: function() {
        localStorage.removeItem("TOKEN");
    },

    removeSessionAuthToken: function() {
        sessionStorage.removeItem("TOKEN");
    },

    getToken: function() {
        let token = "";
        let localData = this.getLocalAuthToken();
        let sessionData = this.getSessionAuthToken();
        if (get(localData, "accessToken", "").length) {
            token = get(localData, "accessToken", "");
        }
        if (get(sessionData, "accessToken", "").length) {
            token = get(sessionData, "accessToken", "");
        }
        return token;
    },

    getCountryCode: function() {
        return localStorage.getItem(defaultConfig.keyStorageCountryCode)
    },

    setCountryCode: function (code) {
        localStorage.setItem(defaultConfig.keyStorageCountryCode, code)
    },

    getCompany: function () {
        return localStorage.getItem(defaultConfig.keyStorageCompany)
    },

    setCompany: function (data) {
        localStorage.setItem(defaultConfig.keyStorageCompany, JSON.stringify(data))
    }

};

export default AuthStorage;
