

export const OPEN_SNACK = "OPEN_SNACK"
export const CLOSE_SNACK = "CLOSE_SNACK"

export const openSnack = (data) =>{
    return{
        type: OPEN_SNACK,
        payload: data
    }
};

export const closeSnack = () => {
    return{
        type: 'CLOSE_SNACK'
    }
};