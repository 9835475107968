import {LOGIN_ERROR, LOGIN_PENDING, FETCH_LOGIN, SEND_CODE} from '../../actions/auth/loginActions'

const initialState = {
   loginData: {},
   isLoading: false,
   hasError: false,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_LOGIN:
      return {
        ...state,
        loginData: action.payload,
        isLoading: false
      };

    case  LOGIN_ERROR:
      return {
        hasError: true,
        ...state
      };

    default:
       return state
  }
};

export const profileData = (state = {hasError: false, data:{}}, action) => {

  switch (action.type) {
    case SEND_CODE:
      return {
        ...state,
        data: action.payload,
      };

    case  LOGIN_ERROR:
      return {
        hasError: true,
        ...state
      };

    default:
      return state
  }
};
